/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.thumbnail-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 469px;
  align-self: center;
  margin-bottom: 1rem;
}

.thumbnail-container {
  padding:  0 0.25rem 0 0.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.thumbnail-img {
  width: 100%;
  max-width: 8.5rem;
  cursor: pointer;
}

.thumbnail-container:first-child {
  padding-left: 0;
}

.thumbnail-container:last-child {
  padding-right: 0;
}
 
@media only screen and (min-width: 774px) {
  .thumbnail-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 9rem;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .thumbnail-container {
    padding: 0;
    margin-bottom: 0.5rem;
    max-width: 8.5rem;
  }

  .bracket {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 84px;
    margin-right: 0.25rem;
  }

  .bracket-close {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-right: 0;
    margin-left: 0.25rem;
  }

  .middle-bracket {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 0.4rem;
    max-width: 0.4rem;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    background-color: #f1df6f;
  }

  .bracket-elbow {
    width: 0.65rem;
    height: 0.5rem;
    background-color: #ff9900;
    position: relative;
  }

  .bracket-elbow:after {
    content: '';
    background-color: black;
    height: 0.25rem;
    width: 0.45rem;
    position: absolute;
  }

  .top-left-bracket {
    border-top-left-radius: 0.5rem;
  }

  .top-left-bracket:after {
    top: 0.3rem;
    left: 0.4rem;
    border-top-left-radius: 0.2rem;
  }

  .bottom-left-bracket {
    border-bottom-left-radius: 0.5rem;
  }

  .bottom-left-bracket:after {
    bottom: 0.3rem;
    left: 0.4rem;
    border-bottom-left-radius: 0.2rem;
  }

  .top-right-bracket {
    border-top-right-radius: 0.5rem;
  }

  .top-right-bracket:after {
    top: 0.3rem;
    right: 0.4rem;
    border-top-right-radius: 0.2rem;
  }

  .bottom-right-bracket {
    border-bottom-right-radius: 0.5rem;
  }

  .bottom-right-bracket:after {
    bottom: 0.3rem;
    right: 0.4rem;
    border-bottom-right-radius: 0.2rem;
  }
}