/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

@font-face {
  font-family:"Okuda";
  src: url("../fonts/Okuda.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Okuda2';
  src: url("../fonts/okuda2.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

html {
  font-family: 'Okuda';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
  max-height: 100vh;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ff9900;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color:  rgba(255, 153, 0, 0.4)
}

body{
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  background-color: black;
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #ff9900;
}

@media only screen and (min-width: 1201px) {
  
  body {
    min-width: 1200px;
    max-width: 1200px;
    margin: auto;
    overflow-y: auto;
  }
}

main {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow-y: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-top: -0.5rem;
  overflow: hidden;
}

h1 {
  margin: 0 0 0 0.5rem;
  text-align: right;
  font-weight: inherit;
  margin-top: 1.5rem;
  font-size: 2.3rem;
  margin: 0;
}

@media only screen and (min-width: 480px) {
  h1 {
    font-size: 3em;
  }
}

h2 {
  font-size: inherit;
  padding: 0;
  margin: 0;
  font-weight: inherit;
}

p {
  color: #ff9900;
  font-size: 1.5rem;
  line-height: 1.3;
  -webkit-margin-before: 0.5rem;
          margin-block-start: 0.5rem;
  -webkit-margin-after: 0.5rem;
          margin-block-end: 0.5rem;
}

button, button:focus{
  border: none;
  outline: inherit;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

.at {
  font-family: 'Okuda2';
}

.elbow {
  width: 10.5rem;
  min-width: 9.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  background-color: #f1df6f;
  position: relative;
}

.elbow:after {
  content: '';
  display: block;
  position: absolute;
  width: 2rem;
  height: 1.5rem;
  background: black;
}

.elbow.top-left-elbow {
  border-top-left-radius: 3.75rem;
}

.elbow.top-left-elbow:after {
  right: 0;
  top: 2rem;
  border-top-left-radius: 1.875rem;
}

.elbow.bottom-left-elbow {
  border-bottom-left-radius: 3.75rem;
}

.elbow.bottom-left-elbow:after {
  right: 0;
  bottom: 2rem;
  border-bottom-left-radius: 1.875rem;
}

.bar {
  background-color: #f1df6f;
  height: 2rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.thin-bar {
  background-color: #f1df6f;
  height: 0.75rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  margin-bottom: 1rem;
}

.nub {
  background-color: #f1df6f;
  height: 2rem;
  min-width: 1.5rem;
}

.thin-nub {
  background-color: #f1df6f;
  height: 0.75rem;
  min-width: 0.75rem;
}

.right-nub {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  margin-left: 3px;
}

.left-nub {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  margin-right: 3px;
}

.blue {
  background-color: #99ccff;
}

.white {
  background-color: #ffffcc;
}

.yellow {
  background-color: #ffff33;
}

.red {
  background-color: #cc6666;
}

.black {
  background-color: black;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.label {
  color: #9492C9;
  padding-right: 0.5rem;
}

.body-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: calc(100vh - 5rem);
}

.large-only {
  display: none;
}

@media only screen and (min-width: 600px) {
  .body-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    height: calc(100vh - 8rem);
  }

  .large-only {
    display: inline-block;
  }

  .small-only {
    display: none;
  }
}