/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.about-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.profile-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-width: 600px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.pic-column {
  max-width: 14rem;
}

.pic-column img {
  -o-object-fit: scale-down;
     object-fit: scale-down;
  width: 100%;
  max-height: 100%;
}

.bio-column {
  margin-top: 1rem;
  font-size: 1.5rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0.5rem;
}

.bio-header {
  text-align: center;
  font-size: 1.8rem;
  width: 100%;
  padding-bottom: 0.75rem;
}

.full-bio {
  padding: 1rem 0.5rem 0 0.5rem;
  max-width: 600px;
}

@media only screen and (min-width: 425px) {
  .profile-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .pic-column {
    width: 45%;
    max-height: 14rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .bio-column {
    padding-left: 0.75rem;
    width: 55%;
    margin: 0;
  }
}