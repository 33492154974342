/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.header-text {
  background-color: black;
  font-size: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  height: 2rem;
}

.title-block.dot {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: #ff9900;
  padding: 0;
}

.title-block {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
