/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.nav-bar {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.nav-label {
  background-color: #f1df6f;
  width: 100%;
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2; 
  -webkit-box-pack: center; 
      -ms-flex-pack: center; 
          justify-content: center;
  font-size: 1.8rem;
  padding-bottom: 2.5rem;
  display: none;
}

.nav-btn {
  width: 100%;
  border-right: 3px solid black;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: black;
  font-size: 1.5rem;
  text-align: left;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.engage {
  font-size: 1.4rem;
  border: none;
}

.nav-bottom {
  background-color: #f1df6f;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; 
  -ms-flex-preferred-size: 5rem; 
      flex-basis: 5rem;
}

@media only screen and (min-width: 600px) {
  .nav-bar {
    width: 9rem;
    padding: 0 0 0 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .nav-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 3px solid black;
  }

  .nav-btn {
    border-right: none;
    border-bottom: 3px solid black;
    max-height: 4.5rem;
    cursor: pointer;
  }
}

@media only screen and (min-width: 600px) {
  .nav-btn {
    padding: 1.8rem 0 0 0.3rem;
  }
}