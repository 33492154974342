/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.contact-section {
  height: inherit;
  max-width: 600px;
  margin: auto;
  -ms-flex-item-align: center;
      align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.contact-links-list {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 3rem;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  padding-left: 1rem;
}

.contact-link {
  padding-bottom: 1rem;
}

.contact-btn-label {
  padding: 0.75rem;
  color: black;
  min-width: 5rem;
  margin-right: 0.25rem;
  font-size: 1.6rem;
}

.contact-btn {
  font-size: 1.6rem;
  border-top-right-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem;
  padding-right: 0.75rem;
  padding-left: 0.5rem;
  padding-top: 0.75rem;
  min-width: 12rem;
  text-align: left;
}

.contact-title {
  font-size: 2rem;
}