/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.ui-btn {
  padding: 0.5rem;
  border-radius: 1.3rem;
  font-family: 'Okuda';
  font-size: 1.2rem;
  cursor: pointer;
}

.prev-project-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 1rem;
  margin-right: 0.2rem;
}

.next-project-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 1rem;
  margin-left: 0.2rem;
}

.project-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.project-img img {
  max-height: 20rem;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}

.curr-project-img {
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.project-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  padding: 0;
  font-size: 1.6rem;
  color: black;
}

.contact-title {
  padding: 0 0 0.5rem 0;
  font-size: 1.6rem;
}

.project-title h2 {
  padding-right: 0.5rem;
}

.project-subtitle {
  font-size: 1.3rem;
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.project-nav {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 1rem;
}

.project-link-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.link-btn {
  padding: 0.75rem;
  border-radius: 1.5rem;
  font-size: 1.3rem;
  min-width: 16rem;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  text-align: center;
  margin: 0 0.5rem 0.75rem 0.5rem;
}

.project-details {
  position: relative;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.visible-desc {
  position: absolute;
  top: 78px;
}

.invisible-desc {
  opacity: 0;
}

@media only screen and (min-width: 464px) {
  .project-link-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .link-btn {
    font-size: 1.5rem;
    min-width: 7rem;
  }
}

@media only screen and (min-width: 774px) {
  .project-details {
    width: 600px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }

  .project-img {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .curr-project-img {
    width: inherit;
  }
}

@media only screen and (min-width: 874px) {
  .project-nav {
    width: 700px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
}