.content-container {
  width: 100%;
  min-height: calc(100vh - 14rem);
  max-height: calc(100vh - 14rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-right: 0.5rem;
}

@media only screen and (min-width: 600px) {
  .content-container {
    min-height: calc(100vh - 204px);
    max-height: calc(100vh - 204px); 
  }
}